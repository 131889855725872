import { useEffect, useRef, useState } from 'react';
import './App.css';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primeicons/primeicons.css';
import { Chart } from "react-google-charts";
import moment from 'moment';
import '@fortawesome/fontawesome-free/css/all.min.css';
import InputMask from 'react-input-mask';
import logo from './img/Leventronic_L.png'
import logoDark from './img/Leventronic Dark.png'
import levenpetLogo from './img/levenpet.png'
import { ReactComponent as Br_logo } from './img/Flag_of_Brazil.svg'
import { months, ufs, ufsDropDownData, whatsapp } from './utils';
import { Knob } from 'primereact/knob'
import { Divider } from 'primereact/divider';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { ProgressSpinner } from 'primereact/progressspinner';
import { ProgressBar } from 'primereact/progressbar';
import { StepsSelectEvent } from 'primereact/steps';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { Badge } from 'primereact/badge';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { Dialog } from 'primereact/dialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { SplitButton } from 'primereact/splitbutton';
import { MenuItem } from 'primereact/menuitem';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import { TabPanel, TabView } from 'primereact/tabview';

export const options_bar = {
  allowHtml: true,
  showRowNumber: true,
};

export const formatters_bar = [
  {
    type: "BarFormat" as const,
    column: 4,
    options: {
      width: 120,
    },
  },
];


interface Locacao {
  serial: string,
  client: string,
  uf: string,
  city: string,
  dailys: number,
  last_access: string
}

function App() {
  const header = ['LEVENPET', 'CLIENTE', 'UF', 'CIDADE', 'DIÁRIAS', 'ÚLTIMO USO',
    // ''
  ]
  const [dataGeo, setDataGeo] = useState<Array<Array<any>>>([['', '']]);
  const [data_bar, setData_Bar] = useState([header]);
  const [vents, setVents] = useState<Array<any>>([])
  const [daily, setDaily] = useState([])
  const [data, setData] = useState<Array<any>>([])
  const urlproxy = 'https://sparkgear-corsproxy-4751c9bca52d.herokuapp.com/'
  const [clientsAll, setClientsAll] = useState<Array<any>>([])

  const omie_all = async () => {
    console.log("Executando omie_all")
    const url = 'https://app.omie.com.br/api/v1/geral/clientes/'
    const response = await fetch(urlproxy + url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "call": "ListarClientes",
        "app_key": "1048584284748",
        "app_secret": "d61dc671a919d9de3e30eae4047b9ff2",
        "param": [
          {
            "pagina": 1,
            "registros_por_pagina": 1000,
            "apenas_importado_api": "N"
          }
        ]
      })
    })
    const conteudo = await response.json()
    console.log('Conteudo', conteudo)
    setClientsAll(conteudo.clientes_cadastro)
    setClientesCarregados(true)
  }
  const [diariasCarregadas, setDiariasCarregadas] = useState(false)
  const leven = async () => {
    console.log("Executando leven")
    console.log("Clientes All:", clientsAll)
    const hdr = {
      'Content-Type': 'application/json',
      'Authorization': 'Token b981d3acfbe6b6169c91f0d110ac01c4ba57afd6'
    }
    const response2 = await fetch(urlproxy + 'http://server.leventronic.com:8000/dailyuse/', {
      method: 'GET',
      headers: hdr,
    })
    const ds = await response2.json()
    setDaily(ds)

    const url = 'http://server.leventronic.com:8000/ventilator/'
    const response = await fetch(urlproxy + url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token b981d3acfbe6b6169c91f0d110ac01c4ba57afd6'
      },
    })
    const vs = await response.json()
    setVents(vs)
    const vs_cnpj = vs.filter((v: any) => v.lot !== '')

    setData(await Promise.all(
      vs_cnpj.map(async (ventilator: any) => {
        var client = null
        try {
          if (ventilator.cnpj !== '') client = clientsAll.filter((c: any) => c.cnpj_cpf == ventilator.cnpj)[0]
        } catch {
          client = 'erro'
        }
        return { ...ventilator, client }
      })
    ))
    setDiariasCarregadas(true)
  }
  useEffect(() => {
    console.log("Dataaa:", data)
  }, [data])
  useEffect(() => {
    leven()
  }, [clientsAll])
  useEffect(() => {
    omie_all()
  }, [])
  var mes = '0' + (new Date().getMonth() + 1)
  mes = mes.substring(mes.length - 2, mes.length)
  const [month, setMonth] = useState(mes);
  const [clients, setClients] = useState<Array<any>>([])
  const [dataFiltered, setDataFiltered] = useState<Array<any>>([])
  const [clientFiltro, setClientFiltro] = useState<Array<any>>([])
  const [ventilator, setVentilator] = useState<any>()
  const [d, setD] = useState<any>()
  const [clientesCarregados, setClientesCarregados] = useState(false)
  const [dataPrime, setDataPrime] = useState<Locacao[]>([])
  const [dataPrimeConstant, setDataPrimeConstant] = useState<Locacao[]>([])
  useEffect(() => {
    var data_chart = [header]
    var data_clients = new Array<any>([])
    var data_prime = new Array<any>([])
    data.map((d: any) => {
      var diarias = Array.from({ length: 31 }, () => 0);
      daily.filter((dl: any) => dl.ventilator === d.url && dl.day.split('-')[1] === month).map((dl: any, i) => {
        diarias[Number(dl.day.split('-')[2])] = 1
      })
      data_prime.push({
        'serial': d.serial,
        'client': d.client ? (d.client === 'erro' ? 'Não encontrado' : d.client.nome_fantasia.toUpperCase().substring(0, 20) + (d.client.nome_fantasia.length > 20 ? '...' : '')) : 'Sem alugar',
        'uf': d.client ? (d.client === 'erro' ? '-' : d.client.estado) : '-',
        'city': d.client ? (d.client === 'erro' ? '-' : d.client.cidade.split('(')[0]) : '-',
        'dailys': diarias.reduce((total, dia) => total + dia, 0),
        'last_access': moment(d.date_update).format('DD/MM/YYYY HH[:]mm')
      })
      data_chart.push([
        d.serial,
        d.client ? (d.client === 'erro' ? 'Não encontrado' : d.client.nome_fantasia.substring(0, 30)) : 'Sem alugar',
        d.client ? (d.client === 'erro' ? '-' : d.client.estado) : '-',
        d.client ? (d.client === 'erro' ? '-' : d.client.cidade.split('(')[0]) : '-',
        diarias.reduce((total, dia) => total + dia, 0),
        moment(d.date_update).format('DD/MM/YYYY HH[:]mm'),
        // '<button onclick={window.open("https://wa.me/' + d.client.telefone1_ddd + (d.client.telefone1_numero && d.client.telefone1_numero.replace('-', '')) + '")}><img height="10px" src="https://static.whatsapp.net/rsrc.php/v3/y7/r/DSxOAUB0raA.png"/></button>'
      ])
      if (d.client && d.client !== 'erro')
        data_clients.push({ value: d.url, label: d.client.nome_fantasia })
    })
    setData_Bar(data_chart)
    setDataFiltered(data_chart)
    setClients(data_clients)
    setClientFiltro(data_clients)
    setDataPrime(data_prime)
    setDataPrimeConstant(data_prime)

    var count_uf = Array.from({ length: 27 }, () => 0);
    data_bar.map((d, di) => {
      ufs.map((u, ui) => {
        if (d[2] === u)
          count_uf[ui]++
      })
    })
    var uf_chart: Array<Array<any>> = [["Estado", "Levenpets"]]
    var i = 0
    var ests: Array<string> = []
    count_uf.forEach((u: any) => {
      if (u > 0) {
        uf_chart.push(['BR-' + ufs[i], u])
        ests.push(ufs[i])
      }
      i++
    })
    // setEstados(ests);
    setDataGeo(uf_chart);
    setUF('')
  }, [data, month, clientsAll]);

  const [dataCalendar, setDataCalendar] = useState([
    ['Data', 'Valor'],
    [new Date(2023, 2, 4), 1],
    [new Date(2023, 2, 5), 1],
  ]);

  const [showCalendar, setShowCalendar] = useState(false)
  const colorAxisOptions = {
    values: [0, 1, 3],
    colors: ['transparent', 'red', 'orange']
    // { minValue: 0, maxValue: 1, color: 'transparent' }, // Values 0-3 will be shown in red
    // { minValue: 2, maxValue: 10, color: 'blue' }, // Values 3-6 will be shown in orange
    // { minValue: 11, maxValue: 12, color: 'green' }, // Values 6-10 will be shown in green
  };
  const [options_calendar, setOptionsCalendar] = useState({
    title: "Uso diário do LEVENPET",
    colorAxis: {
      values: [37000, 37250, 37500, 37750, 38000, 38250, 38500],
      colors: [0, 1, 255, 40, 100, 200, 300]
    }
  });

  const load_calendar = (client: any) => {
    console.log('CLIENTE SELECIONADO', client)
    if (client !== '') {
      var datasss = new Array<any>(['Data', 'Valor'])
      daily.filter((d: any) => d.ventilator === client).map((d: any, i) => {

        // setOptionsCalendar({
        //   title: clients.filter((c) => c.value == client)[0].label.toUpperCase()
        //   colorAxis: { colors: colorAxisOptions },
        // })
        const day = d.day.split('-')
        datasss.push([new Date(parseInt(day[0]), parseInt(day[1]) - 1, parseInt(day[2])), 1])
      })
      console.log('DATASSS', datasss.length)
      if (datasss.length > 1) {
        setDataCalendar(datasss)

        setShowCalendar(true)
      } else { setShowCalendar(false) }
    }
    else
      setShowCalendar(false)
  }
  const [uf, setUF] = useState<any>()
  const alterarUF = () => {
    console.log('UF:', uf)
    if (uf?.name) {
      var data = [header]
      var clientss = [{ value: '', label: '' }]
      var dataPrimeFiltered: Locacao[] = []
      dataFiltered.
        filter((d: any) => d[2] === uf).
        map((d: any, i: any) => {
          data.push(d)
          clientss.push(clientFiltro.filter((c: any) => c.label === d[1])[0])
        })
      dataPrimeConstant.filter((d) => d.uf === uf.name).map((d) => dataPrimeFiltered.push(d))
      setDataPrime(dataPrimeFiltered)
      setData_Bar(data)
      setClients(clientss)
    } else {
      setData_Bar(dataFiltered)
      setDataPrime(dataPrimeConstant)
      setClients(clientFiltro)
    }
  }
  useEffect(() => { alterarUF() }, [uf])
  const [showVinc, setShowVinc] = useState(false)
  const vincular = async () => {
    if (isCnpjValid(cnpjj)) {
      const url = ventilator.url
      const response = await fetch(urlproxy + url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token b981d3acfbe6b6169c91f0d110ac01c4ba57afd6'
        },
        body: JSON.stringify({
          "cnpj": cnpjj
        })
      })
      console.log('Ventiador escolhido:', await response.json())
    }
  }
  const changeCNPJ = (event: any) => {
    console.log('Cliente vinculado')
    console.log(ventilator)
    vincular();

  }
  function isCnpjValid(cnpj: string): boolean {
    // Remove caracteres não numéricos
    const cnpjClean = cnpj.replace(/\D/g, '');

    // Verifica se o CNPJ possui 14 dígitos
    if (cnpjClean.length !== 14) {
      return false;
    }

    // Verifica se todos os dígitos do CNPJ são iguais (caso inválido, mas numéricamente válido)
    if (/^(\d)\1+$/.test(cnpjClean)) {
      return false;
    }

    // Calcula o primeiro dígito verificador
    let sum = 0;
    let factor = 5;
    for (let i = 0; i < 12; i++) {
      sum += parseInt(cnpjClean[i]) * factor;
      factor = factor === 2 ? 9 : factor - 1;
    }
    let remainder = sum % 11;
    const firstDigit = remainder < 2 ? 0 : 11 - remainder;

    // Calcula o segundo dígito verificador
    sum = 0;
    factor = 6;
    for (let i = 0; i < 13; i++) {
      sum += parseInt(cnpjClean[i]) * factor;
      factor = factor === 2 ? 9 : factor - 1;
    }
    remainder = sum % 11;
    const secondDigit = remainder < 2 ? 0 : 11 - remainder;

    // Verifica se os dígitos verificadores são iguais aos dois últimos dígitos do CNPJ
    return (
      parseInt(cnpjClean[12]) === firstDigit && parseInt(cnpjClean[13]) === secondDigit
    );
  }
  const [cnpjj, setCNPJJ] = useState('')
  const [invalidCNPJ, setInvalidCNPJ] = useState(false);
  const loadCNPJ = (event: any) => {
    console.log("CNPJ", event.target.value)
    console.log('Valido:', isCnpjValid(event.target.value))
    setCNPJJ(event.target.value)
    setInvalidCNPJ(false)
  }

  // const whatsapp = () => {
  //   console.log('WHATSAPP')
  //   const c = data.filter((d: any) => d.serial == ventilator.serial)[0].client
  //   console.log('Telefone:', c.telefone1_ddd + c.telefone1_numero, 'Contato:', c.contato)
  //   window.open('https://wa.me/55' + c.telefone1_ddd + (c.telefone1_numero && c.telefone1_numero.replace('-', '')) + '?text=' + c.contato)
  // }
  const [selectedLocation, setSelectedLocation] = useState<Locacao>()
  const dt = useRef(null)
  const selectLocation = (location: Locacao) => {
    setSelectedLocation(location)
    setShowVinc(true)
    // console.log('DataLength', data)
    console.log("Selection:", location);
    setVentilator(vents.filter((v) => v.serial == location?.serial)[0])
    try {
      load_calendar(clients.filter((c) => c.label == location?.client)[0].value)
    } catch {
      load_calendar('')
    }
    if (ventilator) {
      const cl = data.filter((d: any) => d.serial == location?.serial)[0]
      console.log('Data filter:', cl)
      setD(cl)
      if (cl.client && cl.client.cnpj_cpf)
        setCNPJJ(cl.client.cnpj_cpf)
      else
        setCNPJJ('')
    }
  }
  const onUnselect = () => {
    console.log('Desselecionado')
    setShowVinc(false)
  }
  const locacaoDaily = (locacao: Locacao) => {
    return <Knob value={Number(locacao.dailys)} max={31} readOnly size={40} />
    // return <ProgressBar value={locacao.dailys}></ProgressBar>
    // return <Badge value={locacao.dailys}></Badge>
  }

  const levenpetBody = (locacao: Locacao) => {
    return <Avatar className="p-overlay-badge" image={levenpetLogo} onClick={() => setShowDialog(true)}>
      <Badge value={locacao.serial?.split('-')[3]} />
    </Avatar>
  }
  const [statusItemTemplate, setStatusItemTemplate] = useState<any>()
  const toast = useRef<Toast>(null)
  const ufRowFilterTemplate = (options: any) => {
    return (
      <Dropdown value={options.value} options={ufsDropDownData()} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
    );
  };
  const [showDialog, setShowDialog] = useState(false)
  const [whatsApp, setWhatsApp] = useState<any>()
  const loadWhatsApp = async () => {
    console.log('Ventilator', ventilator)
    console.log('WhatsApp', whatsApp.replace('9 ', '').replace(' ', '').replace('(', '').replace(')', '').replace('-', ''))
    const response = await fetch(urlproxy + ventilator.url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token b981d3acfbe6b6169c91f0d110ac01c4ba57afd6'
      },
      body: JSON.stringify({
        "phone": whatsApp.replace('9 ', '').replace(' ', '').replace('(', '').replace(')', '').replace('-', '')
      })
    })
    console.log('Ventilador atualizado', await response.json())
  }
  const cleanWhatsApp = async () => {
    const response = await fetch(urlproxy + ventilator.url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token b981d3acfbe6b6169c91f0d110ac01c4ba57afd6'
      },
      body: JSON.stringify({
        "phone": ''
      })
    })
    console.log('Ventilador atualizado', await response.json())
  }
  return (
    <div className="App">
      <Toast ref={toast} />
      <div className='row'>
        <a href='https://leventronic.com'><img className='logo' src={logo} style={{ height: '50px' }} /></a>
        <div className="title"> MONITORAMENTO - LEVENPET</div>
      </div>

      <div style={{ display: 'flex' }}>
        <div className='row-white' style={{ position: 'relative', left: '-40px' }}>
          <h3 className='filter-title'> LEVENPET NO BRASIL <Br_logo style={{ display: 'inline-flex', justifyContent: 'center', paddingLeft: '10px' }} width={30} height={30} /> </h3>
          <br></br>

          <Chart
            style={{ display: 'inline-flex', alignItems: 'center' }}
            chartEvents={[
              {
                eventName: "select",
                callback: ({ chartWrapper }) => {
                  const chart = chartWrapper.getChart();
                  const selection = chart.getSelection();
                  if (selection.length === 0) return;
                  const region = dataGeo[selection[0].row + 1];
                  setUF(region[0].toString().replace('BR-', ''))
                },
              },
            ]}
            chartType="GeoChart"
            height="400px"
            data={dataGeo}
            options={{
              region: 'BR',
              display: 'region',
              resolution: 'provinces',
              displayMode: 'regions',
              legend: 'none',
              colorAxis: { colors: ['#00A9E2', '#00A9E2'] },
            }}
          />
        </div>
        <div className='column' style={{ position: 'relative', left: '-10px' }}>

          <div className='filter-title'>FILTRO
            <div className='filter-param'>ESTADO
              <Dropdown value={uf} onChange={(e) => setUF(e.value)} options={ufs.map((v: any) => { return { name: v, code: v } })} optionLabel="name"
                showClear placeholder="Selecione a UF" className="w-full md:w-14rem" />

            </div>
            <div className='filter-param'> MÊS
              <Dropdown value={month} onChange={(e) => setMonth(e.value)} options={months.map((m: any) => { return { name: m.label, code: m.label } })} optionLabel="name"
                showClear placeholder="Selecione a Mês" className="w-full md:w-14rem" />
            </div>
          </div>
          {
            clientesCarregados && diariasCarregadas ?
              <>
                <div className="card">
                  <DataTable
                    style={{ position: 'relative', left: '-150px' }}
                    // ref={dt} 
                    value={dataPrime}
                    size={'small'}
                    sortField="serial" 
                    sortOrder={1}
                    // removableSort
                    // globalFilterFields={['serial', 'client', 'uf', 'city', 'dailys', 'last_acess']}
                    // emptyMessage="Locação não encontrada."
                    scrollable scrollHeight="400px" resizableColumns
                    selectionMode="single" selection={selectedLocation} onSelectionChange={(e: any) => selectLocation(e.value)}
                    stripedRows
                    // onRowSelect={onSelect}
                    onRowUnselect={onUnselect}

                    tableStyle={{ minWidth: '50rem' }} >
                    <Column field="serial" header="LEVENPET" sortable body={levenpetBody} ></Column>
                    <Column field="client" header="CLIENTE" sortable></Column>
                    <Column field="uf" header="UF" sortable></Column>
                    <Column field="city" header="CIDADE" sortable></Column>
                    <Column field="dailys" header="DIÁRIAS" sortable body={locacaoDaily}></Column>
                    <Column field="last_access" header="ÚLTIMO ACESSO" sortable></Column>
                  </DataTable>
                  <Dialog header={'LEVENPET ' + d?.serial?.split('-')[3]} visible={showDialog} style={{ width: '800px' }} onHide={() => setShowDialog(false)}>
                    <TabView >
                      <TabPanel header="Informações " rightIcon="pi pi-info-circle ml-2">
                        <Splitter>
                          <SplitterPanel className="flex align-items-center justify-content-center" size={25} minSize={10}>
                            <p className="m-0">
                              <Avatar className="p-overlay-badge" image={levenpetLogo} size="xlarge">
                                <Badge value={d?.serial?.split('-')[3]} />
                              </Avatar>
                            </p>
                          </SplitterPanel>
                          <SplitterPanel className="flex align-items-center justify-content-center" size={75}>
                            <p className="m-0"><b>Número do Lote: </b>{d?.serial?.length > 4 && ('LV-' + d.serial?.split('-')[1] + '-' + d.serial.split('-')[2])}<br />
                              <b>Numero de Série: </b>{d?.serial?.length > 4 && d.serial}<br />
                              <b>Data de Produção: </b>{d?.serial?.length > 4 && (months[Number(d.serial?.split('-')[1].substring(2, 4))].value.toUpperCase() + '/' + d.serial?.split('-')[1].substring(0, 2))}
                              {/* <Avatar icon="pi pi-calendar" style={{ color: 'white', background: 'var(--primary-color)' }} /> */}
                              {/* <Chip label="Produção" style={{ color: 'white', background: 'var(--primary-color)' }} icon="pi pi-calendar" />{serie.length > 4 && (serie?.split('-')[1].substring(2, 4) + '/' + serie.split('-')[1].substring(0, 2))} */}
                              {/* {TemplateDemo()} */}
                              {/* <Chip label="Apple" icon="pi pi-apple" /> */}
                              {/* <Tooltip target=".custom-tooltip-btn">
                                <img alt="logo" src="https://primefaces.org/cdn/primereact/images/logo.png" data-pr-tooltip="PrimeReact-Logo" height="80px" />
                              </Tooltip> */}

                              {/* <Button className="custom-tooltip-btn" type="button" label="Save" icon="pi pi-check" /> */}
                            </p>
                          </SplitterPanel>
                        </Splitter>
                      </TabPanel>
                      { d?.client && 
                      <TabPanel header="Cliente " rightIcon="pi pi-user">
                        <p className="m-0">
                          <b></b>
                          <Divider align='left'><Chip label='Informações Cadastrais' icon="pi pi-id-card" /></Divider>
                          <b>Razão Social: </b>{d?.client?.razao_social}<br />
                          <b>Nome Fantasia: </b>{d?.client?.nome_fantasia}<br />
                          <b>CNPJ: </b>{d?.client?.cnpj_cpf}<br />
                          <b>Inscrição Estadual: </b>{d?.client?.inscricao_estadual}<br />
                          <Divider align='left'><Chip label='Endereço' icon="pi pi-map-marker" /></Divider>
                          <Splitter>
                            <SplitterPanel className="flex align-items-center justify-content-center" size={80}>
                              <b>Endereço: </b>{d?.client?.endereco + ', ' + d?.client?.endereco_numero}<br />
                              <b>Bairro: </b>{d?.client?.bairro + ' - CEP ' + d?.client?.cep.substring(0, 5) + '-' + d?.client?.cep.substring(5, 8)}<br />
                              <b>Cidade: </b>{d?.client?.cidade}
                            </SplitterPanel>
                            <SplitterPanel className="flex align-items-center justify-content-center" size={20}>
                              <Button icon="pi pi-external-link" rounded size="small" onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  'https://www.google.com/maps/place/' + d?.client?.endereco.replace(' ', '+') + '+' + d?.client?.endereco_numero + '+' + d?.client?.cidade.replace(' ', '+'),
                                  '_blank' // <- This is what makes it open in a new window.
                                );
                              }} />

                            </SplitterPanel>
                          </Splitter>
                        </p>
                      </TabPanel> }
                      <TabPanel header="Locação " rightIcon="pi pi-truck">
                        <p className="m-0">
                          <Divider align='left'><Chip label='LOGÍSTICA' icon="pi pi-id-card" /></Divider>
                          {d?.state === 0 && <Button icon="pi pi-check" severity="secondary" label="Concluir" />}
                          {d?.state === 1 && <Button icon="pi pi-check" severity="secondary" label="Reservar" />}
                          {d?.state === 2 && <Button icon="pi pi-check" severity="secondary" label="Enviar" />}
                          {d?.state === 3 && <Button icon="pi pi-check" severity="secondary" label="Confirmar" />}
                          <Divider align='left'><Chip label='TESTES' icon="pi pi-id-card" /></Divider>
                          {d?.state === 3 && <Button icon="pi pi-check" severity="secondary" label="Em testes" />}
                          {(d?.state === 3 || d?.state === 4) && <Button icon="pi pi-check" severity="secondary" label="Alugar" />}
                          <Divider align='left'><Chip label='ALUGUEL' icon="pi pi-id-card" /></Divider>
                          {d?.state === 4 && <Button icon="pi pi-check" severity="secondary" label="Desistência" />}
                          {d?.state === 4 && <Button icon="pi pi-check" severity="secondary" label="Voltar" />}
                          <Divider align='left'><Chip label='MANUTENÇÃO' icon="pi pi-id-card" /></Divider>
                        </p>
                      </TabPanel>

                      {d?.client && <TabPanel header="Contato " rightIcon="pi pi-phone mr-2">
                        <p className="m-0">
                          {d?.client?.contato && <><b>Contato: </b><Chip label={d?.client?.contato} icon='pi pi-user' /><br /></>}
                          <b>Telefones: </b><Chip label={'(' + d?.client?.telefone1_ddd + ') ' + d?.client?.telefone1_numero} icon="pi pi-phone" />
                          <Button icon="pi pi-whatsapp" rounded size="small" onClick={() => whatsapp(d.client.telefone1_ddd + d.client.telefone1_numero.replace('-', ''), d.client.nome_fantasia)} /><br />
                          <b>Email: </b><Chip label={d?.client?.email?.split(',')[0]} icon='pi pi-at' />
                          <Button icon="pi pi-send" rounded size="small" onClick={(e) => { e.preventDefault(); window.location.href = 'mailto:' + d?.client?.email }} />
                        </p>
                      </TabPanel> }
                      <TabPanel header="Alarmes " rightIcon="pi pi-bell mr-2">
                        <p className="m-0">
                          <b>WhatsApp para receber alarmes configurado em:</b>
                          {d?.phone.length > 9 &&<div className="p-inputgroup flex-1">
                             <Chip label={'(' + d?.phone.substring(0, 2) + ') 9 ' + d?.phone.substring(2, 6) + '-' + d?.phone.substring(6, 10)} icon="pi pi-whatsapp" />
                            <Button icon="pi pi-whatsapp" className="p-button-success" onClick={() => whatsApp(d?.phone)} />
                            <Button icon="pi pi-trash" className="p-button-danger" onClick={cleanWhatsApp} />
                          </div>}
                          <div className="p-inputgroup flex-1">
                            <span className="p-float-label">
                              <InputMask id="ssn_input" value={whatsApp} onChange={(e) => setWhatsApp(e.target.value)} mask="(99) 9 9999-9999" />
                              <label htmlFor="ssn_input">WhatsApp</label>
                              <Button icon="pi pi-save" className="p-button-secondary" onClick={loadWhatsApp} />
                            </span>

                          </div>
                        </p>
                      </TabPanel>
                    </TabView>

                  </Dialog>
                </div>
              </>
              :
              <><br />
                <ProgressSpinner style={{ height: '100%', border: '#ff0000' }} />
              </>

          }
          {
            showVinc && ventilator && (
              <h3 className='filter-param'>ALUGAR {ventilator.serial} AO CLIENTE DE CNPJ <InputMask mask={"99.999.999/9999-99"} onChange={loadCNPJ} value={cnpjj} />
                <button onClick={changeCNPJ}>ALUGAR</button>
              </h3>
            )}
          {/* {d && d.client && (
            <>
              <h3 className='filter-param'> {d && d.client && d.client.contato && d.client.contato} <button onClick={whatsapp}><img height="10px" src="https://static.whatsapp.net/rsrc.php/v3/y7/r/DSxOAUB0raA.png" /></button> </h3>
            </>)} */}

        </div>
      </div>
      {showCalendar &&
        <Chart
          chartType="Calendar"
          width="100%"
          height="200px"
          data={dataCalendar}
          options={options_calendar}
        />}
    </div>
  );
}

export default App;
