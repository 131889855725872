export const months = [
    { value: '01', label: 'JANEIRO' },
    { value: '02', label: 'FEVEREIRO' },
    { value: '03', label: 'MARÇO' },
    { value: '04', label: 'ABRIL' },
    { value: '05', label: 'MAIO' },
    { value: '06', label: 'JUNHO' },
    { value: '07', label: 'JULHO' },
    { value: '08', label: 'AGOSTO' },
    { value: '09', label: 'SETEMBRO' },
    { value: '10', label: 'OUTUBRO' },
    { value: '11', label: 'NOVEMBRO' },
    { value: '12', label: 'DEZEMBRO' },
];
export const ufs = ['AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MG', 'MS', 'MT', 'PA', 'PB', 'PE', 'PI', 'PR', 'RJ', 'RN', 'RO', 'RR', 'RS', 'SC', 'SE', 'SP', 'TO']
export const ufsDropDownData = () => {
    return ufs.map((value: string, index: number, array: string[]) => {
        return {name: value, code: value}
    })
}
export const getUFs = async () => {
    const response = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
    console.log(response)
}
export const whatsapp = (celular: string, name: string) => {
    window.open('https://wa.me/55' + celular + '?text=' + name)
  }